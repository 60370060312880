<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <perseu-card title="Formulário de Cromossomo">
      <v-form ref="form" slot="content">
        <v-textarea
          autofocus
          auto-grow
          label="Descrição *"
          v-model.trim="chromosome.description"
          :rules="[$rules.requiredTextarea]"
          rows="3"
        />
      </v-form>
      <div slot="actions" class="justify-center">
        <v-btn color="secondary" @click="close">Salvar</v-btn>
      </div>
    </perseu-card>
  </v-dialog>
</template>

<script>
import PerseuInputImage from "@/components/shared/PerseuInputImage";
import ProceduresForm from "@/components/Chakras/ProceduresForm";
export default {
  name: "FormChromosome",
  data: () => ({
    chromosome: {},
    dialog: false,
  }),
  methods: {
    create() {
      this.chromosome = {};
      this.$refs?.form?.reset();
      this.dialog = true;
    },
    edit(chromosomeParam) {
      this.chromosome = chromosomeParam;
      this.dialog = true;
    },
    close() {
      if (!this.isValid()) return;
      this.$emit("close", this.chromosome);
      this.dialog = false;
    },
    isValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped></style>
